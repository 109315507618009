import React, { useEffect, useRef, useState } from 'react'
import './create-mc-form.styles.scss';
import Modal, { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import TextEditor from '@/components/SHARED/text-editor/text-editor.component';
import OutcomeModal from './outcome-modal.component';
import ComponentModal from './component-modal.component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { setTempMc } from '@/state/slices/contentSlice';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { getUploadUrlFromPath } from '@/utils/getUrlFromFbPath';

const CreateMcForm = ({ mcList, users, cancel, convert }) => {

    const tempMc = useSelector(state => state.contentData.tempMc);
    const profile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({})
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ file, setFile ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    const [ overviewDescription, setOverviewDescription ] = useState(null);
    const [ closingDescription, setClosingDescription ] = useState(null);
    const [ showOutcomeModal, setShowOutcomeModal ] = useState(false);
    const [ showCompModal, setShowCompModal ] = useState(false);
    const [ outcomeToEdit, setOutcomeToEdit ] = useState(null);
    const [ compToEdit, setCompToEdit ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ materialUrl, setMaterialUrl ] = useState(null);
    const { mcId } = useParams();
    const dispatch = useDispatch();
    const dataRef = useRef();
    const outcomeRef = useRef();
    const compRef = useRef();
    const approveRef = useRef();
    const storage = getStorage();

    useEffect(() => {
        return () => {
            dataRef.current = {};
            setFormData(dataRef.current);
            dispatch(setTempMc(null));
        }
    }, [])

    useEffect(() => {
        if (!mcId || !mcList) {return;}
        console.log(mcList);
        dataRef.current = {...mcList[mcId]}
        setFormData(dataRef.current);
        setOverviewDescription(dataRef.current.overview)
        setClosingDescription(dataRef.current.closing)

        async function getMaterialUrlFromPath() {
            if (dataRef.current.materials && !dataRef.current.materials.includes('https://')) {
                const url = await getUploadUrlFromPath(dataRef.current.materials);
                setMaterialUrl(url);
            } else if (dataRef.current.materials) {
                setMaterialUrl(dataRef.current.materials);
            }
        }
        getMaterialUrlFromPath();
    }, [mcId, mcList])

    useEffect(() => {
        if (!tempMc || mcId) {return;}
        dataRef.current = tempMc;
        setFormData(dataRef.current);
        setOverviewDescription(tempMc.overview)
        setClosingDescription(tempMc.closing)
    }, [tempMc, mcId])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        console.log(dataRef.current);
    }

    function handleFileUpload (e) {
        function cancelUpload() {
            document.getElementById('file-upload').value = null;
            setFile(null);
            // setFileName(null);
            return;
        }
        const file = e[0];
        const ext = file.name.match(/\.([^\.]+)$/)[1];
        console.log(ext);
        const allowed = ['jpg','bmp','png','gif','tif','xls','xlsx','pdf','doc','docx']
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            cancelUpload();
            return;
        }
        console.log(file.size);
        if (file.size > 2 * 1024 * 1024) {
            setAlertMessage('That file is too large to be uploaded. You cannot upload files larger than 2MB.');
            cancelUpload();
            return;
        }
        setFile(file);
    };

    function editOutcome(eo, toDelete) {
        if (toDelete) {
            outcomeRef.current = {...formData.content[eo.id]};
            approveRef.current = deleteOutcome;
            setConfirmMessage(`This action will delete ${formData.content[eo.id].name} INCLUDING the components you created for this outcome. Do you want to continue?`)
            return;
        }
        setOutcomeToEdit(eo.id);
        setShowOutcomeModal(true);
    }

    const deleteOutcome = () => {
        console.log(outcomeToEdit);
        const newContent = {...formData.content}
        delete newContent[outcomeRef.current.id]
        dataRef.current = {...formData, ...{'content': newContent}};
        setFormData(dataRef.current);
    }

    function editComp(comp, toDelete) {
        if (toDelete) {
            compRef.current = comp;
            approveRef.current = deleteComp;
            setConfirmMessage(`This action will delete component ${comp.id} under ${formData.content[comp.eo].name}. Do you want to continue?`)
            return;
        }
        console.log(comp)
        setCompToEdit({'comp': comp.id, 'eo': comp.eo});
        setShowCompModal(true);
    }

    const deleteComp = () => {
        let tempEo = {...formData.content[compRef.current.eo]};
        const tempComps = {...tempEo.comp}
        delete tempComps[compRef.current.id];
        const newEo = {...tempEo, ...{'comp': tempComps}};
        const newContent = {...formData.content, ...{[compRef.current.eo]: newEo}}
        dataRef.current = {...formData, ...{'content': newContent}};
        setFormData(dataRef.current);
        setCompToEdit(null);
        return true;
    }

    async function handleSubmit() {
        let tempData = {...formData};
        const req = ['name', 'mc_key', 'nfe_code']
        for (let r of req) {
            if (!tempData[r]) {
                setAlertMessage('Please include a name, NFE Key and an NFE Code to save this Micro-Credential.')
                return;
            }
        }
        setLoading(true);
        const now = new Date().getTime();
        if (file) {
            const pathname = `${tempData.mc_key}/${now}-${file.name}`
            const uploadRef = ref(storage, pathname)
            const metadata = {
                contentType: file.type,
                customMetadata: {
                    cst_key: profile.cst_key,
                    mc_key: tempData.mc_key,
                }
            };
            try {
                await uploadBytes(uploadRef, file, metadata)
            } catch (err) {
                setAlertMessage(`Something went wrong uploading your file. Please try again later. (${err})`);
                return;
            }
            tempData.materials = pathname;
        } 
        if (!tempData.ts_created) {
            tempData.ts_created = now;
        }
        tempData.ts = now;
        tempData.status = 'active';
        if (convert) {
            convertMc(tempData, now);
            return;
        }
        const res = await UseCloudFunction('saveMicrocredential', {'mcData': tempData, 'appId': profile.appId})
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (${err})`);
            setShowAlert(true);
            return;
        }
        clearForm();
    }

    async function convertMc(tempData, now) {
        tempData.converted = now;
        tempData.convertedBy = profile.email;
        let regs = {
            'mcId': tempData.id,
            'mc_key': tempData.mc_key,
            'name': tempData.name,
            'lastEdit': now,
            'list': [],
            'imported': [],
            'roles': {},
            'status': 'active',
        }
        if (tempData.evals) {
            for (let e of Object.keys(tempData.evals)) {
                const user = Object.values(users).filter(u => u.email === e)[0]
                if (user) {
                    if (!regs.list.includes(user.cst_key)) {
                        regs.list.push(user.cst_key);
                    }
                    if (regs.roles[user.cst_key]) {
                        regs.roles[user.cst_key].push('eval')
                    } else {
                        regs.roles[user.cst_key] = ['eval']
                    }
                }
            }
        }
        // if (tempData.parts) {
            // for (let e of Object.keys(tempData.parts)) {
            //     const user = Object.values(users).filter(u => u.email === e)[0]
            //     if (user) {
            //         regs.imported.push(user.cst_key);
            //         if (!regs.list.includes(user.cst_key)) {
            //             regs.list.push(user.cst_key);
                        
            //         }
            //         if (regs.roles[user.cst_key]) {
            //             regs.roles[user.cst_key].push('part')
            //         } else {
            //             regs.roles[user.cst_key] = ['part']
            //         }
            //     }
            // }
        // }
        
        let newContent = {...tempData.content};
        for (let eo of Object.values(newContent)) {
            let newEo = {...eo};
            newEo.num = Number(newEo.num);
            newContent[newEo.id] = newEo;
        }
        tempData.content = newContent;
        delete tempData.evals;
        delete tempData.parts;
        console.log(tempData);
        console.log(regs);
        const res = await UseCloudFunction('saveMicrocredential', {'mcData': tempData, 'regs': regs, 'appId': profile.appId, 'converted': true})
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (${err})`);
            setShowAlert(true);
            return;
        }
        clearForm();
        
    }

    function clearForm() {
        setLoading(false);
        setClearEditor(true);
        setOverviewDescription('');
        setClosingDescription('');
        dataRef.current = {};
        setFormData(dataRef.current);
        cancel();
    }

    return (
        <div className='create-mc-form'>
            <div className='field'>
                <label>Micro-Credential Name</label>
                <input type='text' value={formData.name ?? ''} onChange={(e) => saveData('name', e.target.value)} />
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>Upload Micro-Credential Material Document</label>
                {
                    (materialUrl)
                    ?
                    <div className='g-list-item uploaded-materials'>
                        <b>File Uploaded:</b> <a href={materialUrl} target='_blank'><button className='g-button text'>View Uploaded File</button></a></div>
                    :
                    <input id="file-upload" type="file" onChange={(e) => handleFileUpload(e.target.files)} />
                }
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>Evaluation Link</label>
                <input type='text' value={formData.evalLink ?? ''} onChange={(e) => saveData('evalLink', e.target.value)} />
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>Micro-Credential Requirement Information Link</label>
                <input type='text' value={formData.infoLink ?? ''} onChange={(e) => saveData('infoLink', e.target.value)} />
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>Badge Image URL</label>
                <input type='text' value={formData.imageUrl ?? ''} onChange={(e) => saveData('imageUrl', e.target.value)} />
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>NetForum Event Unique Key</label>
                <input type='text' value={formData.mc_key ?? ''} onChange={(e) => saveData('mc_key', e.target.value)} />
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>NetForum Event Code</label>
                <input type='text' value={formData.nfe_code ?? ''} onChange={(e) => saveData('nfe_code', e.target.value)} />
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>Micro-Credential Summary (one sentence)</label>
                <textarea rows="2" value={formData.summary ?? ''} onChange={(e) => saveData('summary', e.target.value)} ></textarea>
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>Overview Question(s)</label>
                <TextEditor setTextContent={(e) => saveData('overview', e)} textContent={formData.overview} existingContent={overviewDescription} clearEditor={clearEditor} setClearEditor={setClearEditor}/>
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>Essential Outcomes and Components</label>
                <div className='g-list-item essential-outcomes'>
                    <div className='outcome-buttons'>
                        <button className='g-button' onClick={() => setShowOutcomeModal(true)}>Add an Essential Outcome</button>
                        <button className={`g-button ${!formData.content ? 'disabled': ''}`} onClick={() => setShowCompModal(true)}>Add a Component</button>
                    </div>
                    <div className='g-list-item'>
                        {
                            (formData.content && Object.values(formData.content).length > 0) 
                            ?
                            Object.values(formData.content)
                            .toSorted((a,b) => a.num > b.num ? 1 : -1)
                            .map(e => (
                                <div key={e.num} className='eo-item'>
                                    <div className='eo-title'>
                                        <div>{e.name}</div>
                                        <div className='icons'>
                                            {<FontAwesomeIcon className='clickable static edit-icon' title='Edit Outcome Details' icon={faPen} onClick={() => editOutcome(e)} />}
                                            {<FontAwesomeIcon className='clickable static edit-icon delete' title='Delete Outcome' icon={faTrash} onClick={() => editOutcome(e, true)} />}
                                        </div>
                                    </div>
                                    <div className='eo-desc'>{e.desc}</div>
                                    <div className='g-space-20'></div>
                                    {
                                        (Object.values(e.comp).length > 0)
                                        &&
                                        Object.values(e.comp).sort((a,b) => a.id > b.id ? 1 : -1)
                                        .map(c => (
                                            <div key={c.id} className='comp-item'>
                                                <div className='comp-info'>
                                                    <div><b>{c.name}:</b> {c.desc}</div>
                                                    <div>
                                                        {<FontAwesomeIcon className='clickable static edit-icon' title='Edit Component' icon={faPen} onClick={() => editComp(c)} />}
                                                        {<FontAwesomeIcon className='clickable static edit-icon delete' title='Delete Component' icon={faTrash} onClick={() => editComp(c, true)} />}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <hr />
                                </div>

                            ))
                            :
                            <div className='center'>
                                No Outcomes created yet...
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='g-space-20'></div>
            <div className='field'>
                <label>Closing Reflection</label>
                <TextEditor setTextContent={(e) => saveData('closing', e)} textContent={formData.closing} existingContent={closingDescription} clearEditor={clearEditor} setClearEditor={setClearEditor}/>
            </div>
            <div className='buttons'>
                <button className='g-button' onClick={() => clearForm()}>Cancel</button>
                {
                    (loading) 
                    ?
                    <button className='g-button'><ThreeDotsLoader /></button>
                    :
                    <button className='g-button primary' onClick={() => handleSubmit()}>Save Micro-Credential</button>
                }
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <OutcomeModal show={showOutcomeModal} cancel={() => setShowOutcomeModal(false)} formData={formData} saveData={saveData} edit={outcomeToEdit} clearEdit={() => setOutcomeToEdit(null)} />
            <ComponentModal show={showCompModal} cancel={() => setShowCompModal(false)} formData={formData} saveData={saveData} edit={compToEdit} clearEdit={() => setCompToEdit(null)} />
        </div>
    )
}

export default CreateMcForm